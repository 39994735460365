#tabla-busqueda__style{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 99%;
    width: 100%;
    margin-left: 0;
    margin-right: 0 !important;
}

.seccion-detalle-ticket #ticket-tabla-busqueda,
.seccion-detalle-ticket #tarea-tabla-busqueda{
    width: 100% !important;
    margin: 0 !important;
    box-shadow: none;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}


#ticket-tabla-busqueda-in{
    margin: 0;
    width: 95%;
}

div#tarea-tabla-busqueda{
    justify-content: left;
}

div#ticket-tabla-busqueda.seccion-tabla,
div#tarea-tabla-busqueda.seccion-tabla{
    padding: 0rem;
}
#tarea-tabla-busqueda-in.seccion-tabla-interno{
    margin: 0;
}