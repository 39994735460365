.rdt_TableHead {
  height: 2.8rem;
}

.rdt_TableHeadRow .rdt_TableCol {
  background: var(--darkcolor);
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.7rem !important;
  height: 2.8rem;
}

.rdt_TableBody > div.rdt_TableRow {
  min-height: unset;
}

.rdt_TableRow .rdt_TableCell {
  height: 2.5rem;
}

.rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
  transition: 0.3s all linear;
}

.rdt_TableHeadRow .rdt_TableCol:hover .rdt_TableCol_Sortable {
  color: #fff;
}

.sc-crrsfI.rdt_TableCol_Sortable,
.sc-crrsfI.rdt_TableCol_Sortable:focus {
  color: #fff;
}

.rdt_TableHeadRow .rdt_TableCol:first-child {
  border-top-left-radius: 0.3rem;
}

.rdt_TableHeadRow .rdt_TableCol:last-child {
  border-top-right-radius: 0.3rem;
}

.task-start-date,
.task-end-date {
  font-size: 0.6rem !important;
  word-wrap: break-word;
}

.ticket-date {
  font-size: 0.6rem !important;
  word-wrap: break-word;
}

.task-status > p,
.ticket-status > p {
  font-size: 0.7rem !important;
}

.index-update {
  width: 100%;
}

.index-action-btn {
  color: var(--darkcolor);
  transition: 0.15s all linear;
}

.index-update-btn {
  background: none;
  border: none;
  margin: 3px;
  padding: 0;
  height: fit-content;
}

.index-action-btn:hover {
  color: var(--orangecolor);
}

.index-update > small {
  font-size: 0.6rem !important;
}

.taken-task:hover {
  color: var(--redcolor);
}

.panel_usuarios {
  margin: 5rem 1.2rem auto 5rem;
}

.panel_usuarios_marco_interno {
  display: flex;
  padding: 0.1rem;
}

.panel_usuarios_marco_interno > div {
  margin: 0 1rem;
}

.panel_usuarios_izquierda {
  width: 25%;
}

.panel_usuarios_derecha {
  width: 74%;
}

.panel_usuarios_titulo,
.panel_usuarios_tipo_ticket {
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/*LADO IZQUIERDO*/
.panel_usuarios_titulo > h2 {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 0;
}

.panel_usuarios_filter {
  display: flex;
}

.panel_usuarios_filter {
  width: 100%;
  height: 3rem;
}

.panel_usuarios_filter > input {
  width: 85%;
  min-height: 2.4rem;
}

.panel_usuarios_filter > button {
  min-height: 2.4rem;
  min-width: 15%;
  font-size: 1.2rem;
}

.panel_usuarios_crear_ticket > form > div {
  border: 1px solid var(--input-border);
  padding: 0.7rem;
}

p.panel_usuarios_crear_ticket_nuevo {
  margin: 0;
  font-size: 1.2rem !important;
  font-weight: 100;
}
small.panel_usuarios_crear_ticket_aclaracion {
  font-size: 0.9rem !important;
}

small.error_color {
  color: var(--redcolor);
}

.panel_usuarios_crear_ticket_inputs {
  padding-top: 1rem;
}

.panel_usuarios_crear_ticket_inputs > input {
  margin-bottom: 0.5rem;
  height: 2rem;
  width: 100%;
  border-radius: 0.2rem;
  border: 1px solid var(--input-border);
  outline: none;
  padding-left: 0.5rem;
  transition: 0.3s all linear;
}

.panel_usuarios_crear_ticket_inputs > input:focus {
  border: 1px solid var(--darkcolor_soft);
}

.panel_usuarios_crear_ticket_inputs > textarea {
  resize: none;
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.panel_usuarios datalist {
  display: none !important;
}

.panel_usuarios_crear_ticket > form > button {
  display: flex;
  background: var(--thirdcolor);
  border: none;
  border-radius: 0.3rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.3rem;
  margin: 0.5rem 0;
}

.panel_usuarios_crear_ticket > form > button > small,
.panel_usuarios_crear_ticket > form > button > div {
  display: flex;
  font-size: 1.5rem !important;
  align-items: center;
  margin-right: 0.5rem;
  height: 100%;
  color: #fff;
}

.panel_usuarios_crear_ticket > form > button > div {
  font-size: 2rem !important;
}

/*LADO DERECHO*/

.panel_usuarios_tipo_ticket > ul {
  display: flex;
  padding-left: 0;
  margin: 0;
  padding: 0.7rem;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid var(--input-border);
  border-radius: 0.3rem;
}

.panel_usuarios_tipo_ticket > ul > a {
  text-decoration: none;
  color: var(--darkcolor);
  list-style: none;
  font-size: 1.2rem;
  padding: 0.2rem 0.5rem;
  transition: 0.3s all linear;
}

.panel_usuarios_tipo_activo {
  background: var(--darkcolor_soft);
  border-radius: 0.3rem;
  color: #fff !important;
}

.panel_usuarios_tipo_ticket > ul > a:hover {
  background: var(--darkcolor_soft);
  border-radius: 0.3rem;
  color: #fff;
}

.rdt_TableHeader {
  display: none !important;
}

/* Top left swatch user panels */

.swatch_user_top_left {
  padding: 0.7rem;
  margin-bottom: 1rem;
}

.swatch_user_top_left > span {
  font-size: 1.6rem !important;
  margin-bottom: 0;
}
/* ///////////////////////////////////////// */
/* ///////////////////////////////////////// */
/* ///////////////////////////////////////// */
/* ///////////////////////////////////////// */
/* ///////////////////////////////////////// */
@import url("./swatch-users-forms.css");
@import url("./swatch-user-detail.css");
