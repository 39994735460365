.swatch-user-profile-detail {
  display: flex;
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  flex-wrap: wrap;
  justify-content: space-between;
}

.swatch-user-profile-detail > div {
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;
}

.swatch-user-profile-detail .profile-role label {
  margin-bottom: 0 !important;
  font-size: 1rem;
}

.swatch-user-profile-detail > div:not(:first-child) {
  border-radius: 0.3rem;
  border: 1px solid var(--input-border);
  display: flex;
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
}

.swatch-user-profile-detail > div:not(:first-child) small {
  font-size: 1rem;
}

.swatch-user-profile-detail > div:not(:first-child) input {
  width: 10% !important;
  margin: 0 10px;
}
.swatch-user-profile-detail > .swatch_user_detail_delete_user {
  width: 100% !important;
  flex: 0 0 100% !important;
}
.swatch-user-profile-detail > .swatch_user_detail_delete_user > button {
  padding: 0.5rem;
  width: 100%;
}

.swatch_user_detail_is_destination.blocked,
.swatch_user_detail_same_destination.blocked {
  background-color: var(--input-border);
}

.swatch_user_detail_delete_user {
  border: unset;
}
.swatch_user_detail_delete_user button {
  border: unset;
  width: 100%;
  height: 100%;
  border: unset;
  border-radius: 0.3rem;
  background-color: var(--orangecolor2);
  color: #fff;
  font-size: 1rem;
  transition: 0.4s all ease-in-out;
}

.swatch_user_detail_delete_user button:hover {
  background-color: var(--redcolor);
}

.swatch_user_delete .ot_watchmaker_repair_modal_top,
.swatch_user_delete .ot_watchmaker_repair_modal_bottom {
  border-bottom: none;
  border-top: none;
}
