/*@media (min-width: 750px) {
    div.seccion-tabla.charts{
        margin-bottom: 0rem;
    }
}*/

.general_charts_panel{
    align-items: flex-start;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.general_charts_division{
    display: flex;
    flex-direction: column;
    margin: 0 .5rem;
    width: 45%;
    overflow:hidden;
}
.general_charts_division:first-child{
    padding-right: 1.5rem;
    margin-right: 1rem;
    border-right: 3px solid var(--input-border);
}

.general_charts_division p{
    margin: 0;
}

.general_charts_pie_n_board{
    display: flex;
    justify-content: space-between;
}

.general_charts_tickets,
.general_charts_bar,
.general_charts_tasks{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 240px;
    margin: .5rem 0;
    border: 1px solid var(--input-border);
    border-radius: .3rem;
}

.general_charts_tickets,
.general_charts_tasks{
    width: 65%;
}

.general_charts_bar{
    width: 100%;
    height: 220px;
}

.general_charts_tickets > small,
.general_charts_bar > small,
.general_charts_tasks > small{
    padding-left: 1rem;
}

.general_charts_tasks_flex{
    display: flex;
}

.general_charts_no_legend{
    display: flex;
}

.general_charts_boards{
    width: 33%;
    margin:.5rem 0;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.general_charts_daily,
.general_charts_monthly{
    background: var(--midcolor);
    border-radius: .3rem;
    color: #fff;
    height: 48%;
    padding: .5rem;
}

.general_charts_daily > div,
.general_charts_monthly > div{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.general_charts_daily > div > b,
.general_charts_monthly > div > b{
    font-size: 2.5rem;
    text-align: right;
}

.general_charts_daily > div > p,
.general_charts_monthly > div > p {
    font-size: 1.2rem !important;
}

.general_charts_big_pie{
    width: 60%;
    position: relative;
}

.general_charts_big_pie > small{
    display: flex;
    justify-content: center;
    line-height: 1;
    height: 1rem;
}

.general_charts_big_pie > small > b{
    margin-right: 5px;
}

.general_charts_side{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 250px;
}

.general_charts_small_pie{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100px;
    width: 100%;
}

.general_charts_small_pie > small {
    width: 100%;
    text-align: center;
}

.general_charts_small_pie > small > b {
    margin-right: 5px;
}

.general_charts_line_chart{
    height: 100%;
    width: 100%;
}