.panel_cliente_crear_ticket.ot > form > div {
	border: none !important;
}

.ot_search_form {
	border: 1px solid var(--input-border);
	padding: 0.7rem 0;
	border-top: none;
}

.ot_status {
	align-items: flex-start;
	border-radius: 0.2rem;
	border: 1px solid var(--input-border);
	display: flex;
	flex-direction: column;
	height: 120px;
	justify-content: left;
	overflow-y: auto;
	padding: 0.7rem 0.3rem;
}

.ot_status li {
	align-items: center;
	border-radius: 0.3rem;
	border: 1px solid var(--input-border);
	display: flex;
	flex: 0 0 1;
	font-size: 0.7rem;
	justify-content: space-between;
	margin-bottom: 0.3rem;
	padding: 0.3rem;
	transition: 0.2s all ease-in-out;
	width: 100%;
}
.ot_status li:hover {
	border: 1px solid var(--green);
	box-shadow: var(--small-shadow);
	cursor: pointer;
}
.ot_status li:active {
	background-color: var(--input-border);
}
.ot_status li p {
	margin: 0;
}
.ot_status li i {
	color: var(--input-border);
	transition: 0.2s all ease-in-out;
}
.ot_status li:active i,
.ot_status li:hover i {
	color: var(--green);
}

.ot_button_group_1 {
	justify-content: space-between;
	padding-top: 0 !important;
	display: flex;
}

.ot_button_group_1 button {
	align-items: center;
	border-radius: 0.3rem;
	color: #fff;
	display: flex;
	flex: 0 0 calc(50% - 5px);
	font-size: 1rem;
	justify-content: center;
	padding: 5px 15px;
}

.ot_button_group_1 .ot_create_button {
	transition: 0.3s all linear;
	border: none;
}

.ot_button_group_1 button.ot_search_button {
	transition: 0.3s all linear;
	border: none;
}

.ot_button_group_1 button.ot_search_button > small > i {
	margin-right: 0.5rem;
}

.ot_button_group_1 button:hover {
	box-shadow: var(--shadow);
}

[id^='controlled-ot-tabs-tab-'] {
	padding: 0px;
}
[id^='controlled-ot-tabs-tab-'].active .ot_tab_icon {
	color: var(--darkcolor);
}

.ot_tab_icon {
	font-size: 1rem;
	color: var(--darkcolor_soft);
	padding: 3px 9px;
	border-radius: 3px;
	margin: 1px;
	transition: 0.25s all ease-in-out;
}

[id^='controlled-ot-tabs-tab-'].active .ot_tab_icon {
}

.ot_tab_icon:hover {
	color: var(--darkcolor);
	background-color: var(--input-border);
}

.swatch_user_status_change_form {
	padding: 0.3rem;
	border: 1px solid var(--input-border);
	border-radius: 0.3rem;
	border-top: none;
	border-start-start-radius: unset;
	border-start-end-radius: unset;
}

.swatch_user_status_change_form .swatch_user_status_change_top {
	padding-bottom: 0;
}

.swatch_user_status_change_form .swatch_user_status_change_body {
	padding: 0.5rem 0.7rem;
}

.swatch_user_status_change_list {
	width: 100%;
	margin-bottom: 5px;
}

.swatch_user_status_change_list label {
	margin-bottom: 0 !important;
	display: block;
}
.swatch_user_status_change_list small {
	font-size: 0.7rem !important;
}
.swatch_user_status_change_list input {
	width: 100%;
	padding: 0.3rem;
	border-radius: 0.3rem;
	border: 1px solid var(--input-border);
	transition: 0.3s all ease-in-out;
}

.swatch_user_status_change_list input:hover,
.swatch_user_status_change_list input:active,
.swatch_user_status_change_list input:focus {
	border: 1px solid var(--darkcolor);
	outline: unset !important;
}

#swatch_user_status_change_input_user_list::placeholder,
#swatch_user_status_change_input_type_list::placeholder/* ,
#swatch_user_status_change_input_user_list::-webkit-input-placeholder,
#swatch_user_status_change_input_type_list::-webkit-input-placeholder,
#swatch_user_status_change_input_user_list::-moz-placeholder,
#swatch_user_status_change_input_type_list::-moz-placeholder,
#swatch_user_status_change_input_user_list:-ms-input-placeholder,
#swatch_user_status_change_input_type_list:-ms-input-placeholder,
#swatch_user_status_change_input_user_list:-moz-placeholder,
#swatch_user_status_change_input_type_list:-moz-placeholder  */ {
	font-size: 0.6rem !important;
}

.swatch_user_status_change_checkboxes {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0;
	flex-wrap: wrap;
}

.swatch_user_status_change_checkboxes input {
	margin-right: 5px;
	margin-bottom: 5px;
}

.swatch_user_status_change_checkboxes label {
	margin-bottom: 0;
}
.swatch_user_status_change_checkboxes label small {
	font-size: 0.7rem !important;
}
.swatch_user_status_change_checkboxes > div {
	flex: 0 0 calc(50% - 5px);
}

.swatch_user_status_change_bottom {
	padding-top: 0 !important;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.swatch_user_status_change_bottom button {
	display: flex;
	align-items: center;
	flex: 1 1 calc(50% - 5px);
	padding: 0.5rem 0.3rem;
	border: 1px solid transparent;
	border-radius: 0.3rem;
	background-color: var(--fourthcolor);
	transition: 0.3s all ease-in-out;
}
.swatch_user_status_change_bottom > .create_normal_user {
	background-color: var(--thirdcolor);
}

.swatch_user_status_change_bottom button:hover {
	background-color: var(--darkcolor);
}


.swatch_user_status_change_bottom button span {
	color: #fff;
	margin: 0 auto;
}

.swatch_user_status_change_bottom button i {
	margin-right: 5px;
}

.swatch_user_status_change_list.complex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.swatch_user_status_change_list.complex label {
	flex: 0 0 100%;
}
.swatch_user_status_change_list.complex > input[type='text'] {
	flex: 0 0 calc(90% - 10px);
}
.swatch_user_status_change_list.complex
	> input[type='text'][data-disabled='true'] {
	background-color: var(--input-border);
}
.swatch_user_status_change_list.complex > input[type='checkbox'] {
	flex: 0 0 10%;
	height: 15px;
}
.swatch_user_status_change_list.complex > button {
	height: 30px;
	flex: 0 0 10%;
	border: none;
	border-radius: 0.3rem;
	transition: 0.3s all ease-in-out;
}
.swatch_user_status_change_list.complex > .ot_search_info.hidden {
	display: none;
}
.swatch_user_status_change_list.complex > .ot_search_info {
	padding: 5px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--input-border);
	width: 100%;
	margin: 10px;
	text-align: left;
	border-radius: 0.3rem;
}
.swatch_user_status_change_list.complex > .ot_search_info > span {
	flex: 0 0 100%;
	text-transform: uppercase;
	margin-bottom: 3px;
}
.swatch_user_status_change_list.complex > .ot_search_info > span > strong {
	text-transform: capitalize;
}

.swatch_user_status_change_list.complex > button:hover {
	background-color: var(--darkcolor);
	color: white;
}
