.seccion-tabla-interno form.task-form{
    background: var(--ltcolor);
    border-radius: .5rem;
    min-height: fit-content;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media (min-width: 520px) {
    .seccion-tabla-interno form.task-form{
        background: none;
    }
}

.task-create-form{
    border-radius: .5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-width: 100%;
    max-width: 1150px;
    padding: 1rem 0;
}

.task-create-form > p {
    font-size: 1rem !important;
}

.task-create-part-a,.task-create-part-b{
    min-width: 35vw !important;
    margin: 0 1rem;
    width: 100%;
}


@media (min-width: 520px) {
    .task-create-part-a,.task-create-part-b{
        margin: 0 1rem;
        width: 40%;
    }
}

.task-create-form label{
    display: block;
    padding-top: .5rem;
}

.task-create-form select,
.task-create-form option,
.task-create-form textarea,
.task-create-form input{
    width: 100% !important;
    padding: .5rem;
    border-radius: .5rem;
    border: none;
    border-bottom: 2px solid var(--purplecolor);    
    background: var(--ltcolor);
    resize: none;
}

.task_create_required{
    border:2px solid red !important;
}

.seccion-tabla-interno .task-create-buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem ;
}


@media (min-width: 750px) {
    .seccion-tabla-interno .task-create-buttons{
        flex-direction: row;
    }
}

.seccion-tabla-interno button.task-create-button,
.seccion-tabla-interno button.task-create-return{
    text-decoration: none;
    display: inline-block;
    text-align: center;
    width: 80%;
    padding: .5rem ;
    font-size: 1rem;
    margin: .5rem;
    border-radius: .5rem;
    color: var(--ltcolor);
    border: none;

}

@media (min-width: 750px) {
    .seccion-tabla-interno button.task-create-button,
    .seccion-tabla-interno button.task-create-return{
        width: 40%;
    }
}


.seccion-tabla-interno button.task-create-button{
    background: var(--midcolor);
    transition: .3s all linear;
}

.seccion-tabla-interno button.task-create-return{
    background: var(--purplecolor);
    transition: .3s all linear;
}

.seccion-tabla-interno button.task-create-button:hover,
.seccion-tabla-interno button.task-create-return:hover{
    box-shadow: var(--small-shadow);
}

.task-create-from,.task-create-till {
    width: 50%;
}

.task-create-till{
    padding-left: 1rem;
}

.task-create-from{
    padding-right: 1rem;
}

.react-datepicker-wrapper, .react-datepicker__input-container, .inputWidth input {
    width: 100% ;
}

.react-datepicker-popper {
    z-index: 19 !important;
}