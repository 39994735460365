.panel-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    z-index: 10;
}
.panel-ticket {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.panel-ticket-title-description{
    display: flex;
    align-items: flex-start;
    max-width: 50%;
    color: var(--darkcolor);
}

.panel-ticket-title-description h2{
    width: 100%;
    margin: auto .5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (min-width: 520px) {
    .panel-header{
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        background: #fff;
    }

    .panel-ticket-title-description{
        height: 100%;
        width: 100%;
        margin: 0;
        align-items: center;
    }

}



.panel-ticket-title-description small{
    border-left: 3px solid var(--ltcolor);
    margin: 0;
    padding-top: 0;
    padding-left: 1.5rem;
    padding-right: 1rem;
}

@media (min-width: 520px) {
    .panel-ticket-title-description small{
        width: 100%;
        border-left: 3px solid  rgba(15,39,59,.2);
    }
}

.create-buttons{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

@media (min-width: 700px) {
    .create-buttons{
        flex-direction: row;
    }
}

.crear-ticket-button{
    position: relative;
    display: flex;
    width: 60%;
    margin-left: 1rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    margin-right: 5%;
    margin-top: 30%;
    color: var(--darkcolor);
    background: var(--ltcolor);
    text-decoration: none!important;
    border: none;
    cursor: pointer;
    transition: .3s all linear;
}

.crear-ticket-button:hover{
    background: var(--fourthcolor);
}

@media (min-width: 400px) {
    .crear-ticket-button{
        margin-top: 15%;
    }
}

@media (min-width: 520px) {
    .crear-ticket-button{
    width: 8rem;
    margin-top: 2rem;
    margin-right: 2rem;
    color: var(--ltcolor)!important;
    background: var(--darkcolor);
    height: 80px;
    padding-right: .5rem;
    margin-right: 1rem;
    }

    .crear-ticket-button:hover{
        box-shadow: var(--small-shadow);
    }
}

@media (min-width: 700px) {
    .crear-ticket-button{
        width: 9rem;
        height: 1.5rem;
        margin: auto;
        margin-right: 2rem;
    }
}

.crear-ticket-button p{
    padding-right: .5rem;
    margin-bottom: 0;
    font-size: .7rem;
}

.crear-ticket-button h2{
    font-size: 1.5rem;
    margin: 0;
    margin-right: .5rem;
}

@media (min-width: 1300px) {
    small{
        font-size: 1rem !important;
    }

    p{
        font-size: 1.4rem !important;
        margin-left: 1rem;
    }

    .crear-ticket-button {
        padding: 1.3rem 0;
    }

    .crear-ticket-button p {
        font-size: 1rem !important;
    }
}

@media (min-width: 2200px) {
    
    .crear-ticket-button {
        margin-right: 1.4rem;
    }
}

.create-nav-menu{
    position: absolute;
    top: 90%;
    right: 2rem;
    display: flex;
    width: 9rem;
    flex-direction: column;
    border: 1px solid var(--input-border);
}

.create-nav-menu > a{
    border: none;
    padding: .5rem;
    transition: .3s all linear;
    background: var(--midcolor);
    color: var(--darkcolor);
    border-bottom: 1px solid var(--input-border);
}

.create-nav-menu > a:last-child{
    border-bottom: none;
}

.create-nav-menu > a:hover{
    text-decoration: none;
    color: initial;
    background: #fff;
}
