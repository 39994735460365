.errorMessage {
	/*background: rgb(255, 150, 150);
    border: 1px solid var(--redcolor);*/
	padding: 1rem;
	margin: 0 0.5rem;
	color: var(--darkcolor);
	border-radius: 5px;
}

.errorAuthMessage {
	background: rgba(var(--redcolor), 0.2);
	padding: 1rem;
	margin: 0.5rem;
	border: 1px solid var(--redcolor);
	color: var(--redcolor);
	border-radius: 5px;
}

.errorSimple {
	padding: 0;
	color: var(--redcolor);
}

.error404 {
	margin: 5rem auto;
	min-width: 200px;
	max-width: 500px;
	padding: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.error404 div {
	background: var(--ltcolor);
	padding: 2rem;
	color: var(--darkcolor);
	border-radius: 0.5rem;
}

.error404 img {
	max-width: 200px;
	margin: 0;
	padding: 1rem 0 4rem 0;
	text-shadow: var(--small-shadow);
}

.error404 a {
	display: block;
	text-align: center;
	border-radius: 0.5rem;
	background: var(--redcolor);
	color: var(--ltcolor);
	padding: 0.5rem 1rem;
	transition: 0.3s all linear;
}

.error404 a:hover {
	text-decoration: none;
	color: var(--ltcolor);
	box-shadow: var(--shadow);
}
