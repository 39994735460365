.task-update-form{
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filling,
.task-update-type, 
.task-update-priority,
.task-update-company,
.task-update-dependency,
.task-update-derive,
.task-update-derived-to,
.task-update-urgency{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: .3rem;
}


.task-update-company,
.task-update-derived-to,
.task-update-urgency{
    padding-left: 1rem;
}


.task-update-type, 
.task-update-dependency,
.task-update-derive,
.filling,
.task-update-priority{
    padding-right: 1rem;
}
 
.task-update-type select, 
.task-update-company select,
.task-update-derive select,
.task-update-derived-to select,
.filling,
.task-update-dependency select,
.task-update-priority select,
.task-update-urgency select {
    width: 90%;
}


.task-update-form input, 
.task-update-form select {
    border: none;
    border-radius: .7rem;
    border: 1px solid var(--purplecolor);
    padding: .5rem;
}

.task-update-submit{
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
}

.task-update-submit-button{
    width: 90%;
    border:none;
    border-radius: .3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background: var(--darkcolor);
    color: #fff;
}


.task-update-form .for-task-detail{
    width: 100%;
}
.task-update-form .for-task-detail input{
    width: 90%;
    justify-content: left;
}

.task-update-form hr {
    margin: 1.5rem 0 1rem 0;
    background: var(--purplecolor);
    width: 100%;
}
