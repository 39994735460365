.custom-search-form{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 0;
    border-radius: .4rem;
    overflow:auto ;
}

div.custom-search-input{
    min-width: 80px;
    height: 25px;
    margin: 0 5px;
}

div.custom-search-input:first-child{
    margin-left: 0;
}
.custom-search-button.cs-delete{
    margin-right: 0;
}

.custom-search-input input,
.custom-search-input select{
    width: 100%;
    height: 100%;
    border-radius: .3rem;
    border: 1px solid var(--darkcolor);
    padding-left: 3px;
}

.custom-search-button{
    border-radius: .3rem;
    border: none;
    margin: 0 5px;
}

div.custom-search-input .react-datepicker-wrapper,
div.custom-search-input .react-datepicker__input-container{
    height: 100%;
}

datalist#user_filter,
datalist#status_filter,
datalist#company_filter{
    display: none !important;
}