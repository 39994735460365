.subscriptions_edit .modal-dialog {
  max-width: 600px !important;
}

.subscriptions_edit_body {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}
.subscriptions_edit_header h6 {
  margin-bottom: 0;
  margin-top: 10px;
}
.subscriptions_edit_footer,
.subscriptions_edit_header {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.subscriptions_edit_body > small {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.subscriptions_edit_body > .edit {
  display: flex;
  flex: 0 0 calc(50% - 10px);
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(50% - 10px);
  justify-content: space-between;
}
.subscriptions_edit_body > .topics {
  flex: 0 0 calc(50% - 10px);
  display: flex;
  flex-wrap: wrap;
}
.subscriptions_edit_body > .topics > .topics_list {
  align-items: flex-start;
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  list-style: none;
  max-height: 50vh;
  overflow-y: auto;
  padding-left: 0;
}

.subscriptions_edit_body > .topics > span {
  flex: 0 0 100%;
  font-size: 0.7rem;
  margin-bottom: 0;
}

.subscriptions_edit_body > .topics > .topics_list > li {
  border-radius: 0.3rem;
  border: 1px solid var(--darkcolor_soft);
  cursor: pointer;
  flex: 0 0 100%;
  line-height: 1.05;
  margin-bottom: 5px;
  padding: 1px 20px 1px 5px;
  position: relative;
  transition: 0.4s all ease-in-out;
}
.subscriptions_edit_body > .topics > .topics_list > li.active {
  background-color: var(--darkcolor_soft);
  color: white;
}
.subscriptions_edit_body > .topics > .topics_list > li > .menu {
  background-color: transparent;
  border-radius: 50%;
  border: none;
  display: inline-block;
  font-size: 10px;
  height: 12px;
  margin-right: 5px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s all ease-in-out;
  width: 12px;
}
.subscriptions_edit_body > .topics > .topics_list > li:hover > .menu {
  transform: translate(-50%, -50%) scale(1.2);
  font-size: 10px;
}
.topics > .topics_list > li > .menu::before {
  color: white;
  content: "";
  font-size: 0.5rem;
  font-weight: bold;
  left: 50%;
  line-height: 1;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}
.subscriptions_edit_body > .edit > .input_div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}

.subscriptions_edit_body > .edit > .input_div > label {
  flex: 0 0 100%;
  font-size: 0.7rem;
  margin-bottom: 0;
}
.subscriptions_edit_body > .edit > .input_div > input {
  flex: 0 0 100%;
  margin-bottom: 0;
  outline: none !important;
  transition: 0.3s all ease-in-out !important;
}

.subscriptions_edit_body > .edit > .input_div > input:hover {
  border-color: var(--darkcolor);
}

.subscriptions_edit_body > .edit > button.se_button {
  flex: 1 0 auto;
  display: flex;
  width: auto;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.subscriptions_edit_body > .edit > button.se_button > i {
  margin-right: 5px;
}

.subscriptions_edit_body > .edit > .se_checkbox {
  display: block;
  width: 100%;
  flex: 0 0 100%;
}
.subscriptions_edit_body > .edit > .se_checkbox > .form-check > label {
  color: var(--darkcolor) !important;
  cursor: pointer;
}
