

.seccion-tabla{
    margin:60% 4rem 5% 1rem;
    width: 90%;
    overflow: auto;
    padding-bottom: 1rem;  
}

@media (min-width: 320px) {
    .seccion-tabla{
        margin-top: 30%;
    }
}

@media (min-width: 520px) {
    .seccion-tabla{
        width: 100%;
    }
}

@media (min-width: 1250px) {
    .seccion-tabla{
        width: 100vw !important;
    }
}
.profile-special-margin{
    margin-top: 40%;
}
@media (min-width: 520px) {
    .ticket-create-special-margin{
        margin-top: 60%;
    }
}

@media (min-width: 520px) {
    .seccion-tabla{
        margin: 12rem 1rem 0 5rem;
        max-width:93vw;
        padding: 1rem;
        background: white;
        border-radius: .5rem;
        font-size: .7rem;
        height: fit-content;
        box-shadow: var(--shadow);
    }
}

@media (min-width: 750px) {
    .seccion-tabla{
        margin: 4.5rem 1rem 1rem 4rem;
    }
}

.seccion-tabla-interno{
    margin: .2rem;
}

@media (min-width: 520px) {
    .seccion-tabla-interno{
        overflow-x: hidden;
    }
}
@media (min-width: 1300px) {

    .seccion-tabla{
        width: 100%;
    }
    
    .seccion-tabla,.seccion-tabla-interno{
        justify-content: center!important; 
        font-size: .8rem;
    }

    .seccion-tabla-interno{
        width: 90%;
    }

    div.rdt_TableCell{
        width: 60px !important;
    }

    .sc-iktFzd.dIxoMD,
    .sc-jJEJSO.dkQhpE{
        max-height: 1.75rem;
    }
    .sc-jJEJSO.dkQhpE{
        width: 1.75rem;
    }

}



@media (min-width: 1900px) {
    .seccion-tabla-interno td,.seccion-tabla-interno th{
        font-size: 1.2rem !important;
    }

    .dataTables_filter,
    .dataTables_paginate,
    .dataTables_length,
    .dataTables_info{
        font-size: 1.2rem !important;
    }
}

.odd, .even{
    cursor: pointer;
}
