.chart_legend{
    bottom: 3px;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    width: 100%;
}

.chart_legend_single{
    align-items: center;
    display: flex;
}

.chart_legend_color{
    height: 8px;
    margin: 5px 5px 0 5px;
    width: 8px;
}

.chart_legend_name{
    font-size: .6rem !important;
    margin: 5px 0 0 0;
}