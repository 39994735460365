.ticket-create-part-b {
  display: flex;
  flex-direction: column;
}
.ticket-create-part-b > .ticket-create-company {
}

.create-user-companies-container {
  background-color: var(--ltcolor);
  border-bottom: 2px solid var(--purplecolor);
  border-radius: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  height: 160px;
  justify-content: space-between;
  overflow-y: auto;
  padding: 0.7rem;
  align-items: flex-start;
}

.create-user-companies-container > .create-user-company {
  flex: 0 0 calc(50% - 10px);
  margin: 0.3rem 0;
  padding: 0;
}

.create-user-companies-container > .create-user-company > label {
  padding: 0;
  cursor: pointer;
  margin: 0;
  border: 2px solid var(--darkcolor_soft);
  background-color: var(--darkcolor_soft);
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  transition: 0.3s all ease-in-out;
  color: #fff;
}

.create-user-companies-container > .create-user-company.success > label {
  background-color: var(--thirdcolor);
  border-color: var(--thirdcolor);
}
