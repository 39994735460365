div.sc-fodVxV {
    min-height: 0px;
}

header.sc-idOhPF{
    position: relative;
    height: 2rem;
    margin-left: 0;
    padding: 0;
    width: 100%;
    z-index: 9;
    margin-bottom: .5rem;
}

.judJDM{
    min-height: unset !important;
}

.rdt_TableHead{
    height: 2.8rem;
}

.rdt_TableHeadRow{
    height: 2.8rem;
}

.rdt_TableHeadRow .rdt_TableCol{
    background: var(--darkcolor);
    color: rgba(255, 255, 255, 0.6);
    font-size: .7rem !important;
    height: 2.8rem;
}

div.sc-jrAGrp.rdt_TableRow{
    max-height: 2.5rem;
    min-height: unset;
}

div.sc-hKgILt.rdt_TableCell{
    height: 2.4rem;
}

.rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable{
    transition: .3s all linear;
}

.rdt_TableHeadRow .rdt_TableCol:hover .rdt_TableCol_Sortable{
    color:#fff;
}

.sc-crrsfI.rdt_TableCol_Sortable,
.sc-crrsfI.rdt_TableCol_Sortable:focus{
    color: #fff;
}

.rdt_TableHeadRow .rdt_TableCol:first-child{
    border-top-left-radius: .3rem;
}

.rdt_TableHeadRow .rdt_TableCol:last-child{
    border-top-right-radius: .3rem;
}

.task-start-date,
.task-end-date{
    font-size: .6rem !important;
    word-wrap: break-word;
}

.ticket-date {
    font-size: .6rem !important;
    word-wrap: break-word;
}

.ticket_priority{
    font-size: .7rem !important;
    word-wrap: break-word;
}

.task-status > p,
.ticket-status > p{
    font-size: .7rem !important;
}

.index-update{
    width: 100%;
}


.index-action-btn{
    color: var(--darkcolor);
    transition: .15s all linear;
}

.index-update-btn{
    background: none;
    border: none;
    margin: 3px;
    padding: 0;
    height: fit-content;
}

.index-action-btn:hover{
    color: var(--orangecolor);
}

.index-update > small{
    font-size: .6rem !important;
}

.taken-task:hover{
    color: var(--redcolor);
}

.rdt_Pagination{
    min-height: unset !important;
    height: 3rem;
}